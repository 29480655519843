<template>
  <div>
    <el-card class="box-card">
      <div class="wrap">
        <div class="title">订单信息</div>
        <div class="content">
          <el-form label-width="150px">
            <el-form-item label="订单类型：">
              <div>升级企业版</div>
            </el-form-item>
            <el-form-item label="生效时间：">
              <div>{{ startEffectiveTime }} - {{ endEffectiveTime }}</div>
            </el-form-item>
            <el-form-item label="平台使用费：">
              <div>12999.00元/年</div>
            </el-form-item>
            <el-form-item label="使用人数：">
              <div>{{ num }}人</div>
            </el-form-item>
            <el-form-item label="优惠金额：">
              <div>新签免除平台使用费 12999.00元</div>
            </el-form-item>
            <el-form-item label="订单金额：">
              <div style="color:#FF583E">{{ (num * year * 48).toFixed(2) }}元</div>
            </el-form-item>
            <el-form-item label="订单明细：" class="orderDetail">
              <el-table
                :data="tableData"
                border
                :summary-method="getSummaries"
                show-summary
                style="width:408px"
              >
                <el-table-column prop="id" label="类目"> </el-table-column>
                <el-table-column prop="name" label="单价"> </el-table-column>
                <el-table-column prop="amount1" label="数量"> </el-table-column>
                <el-table-column prop="year" label="年"> </el-table-column>
                <el-table-column prop="amount2" label="总价"> </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="支付方式：">
              <div class="payType">
                <div class="balancePay">
                  <img src="../../../assets/college/Accounts/wrap.png" alt="" />
                  <span class="balance">余额支付</span>
                </div>
                <div class="nowMoney">
                  当前余额<span class="moneyNum">{{ accountsDetail.balanceAmount }}</span
                  >元
                </div>
                <div class="recharge" @click="recharge">充值</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="agreement">
        <!-- <div class="agreement_checked">
          <div><el-checkbox v-model="checked"></el-checkbox></div>
          <div class="agreement_text">勾选即同意《协议***********》</div>
        </div> -->
        <div class="btn">
          <el-button type="primary" size="medium" @click="submitPay">确定支付</el-button>
        </div>
      </div>
    </el-card>

    <!-- <Recharge ref="Recharge"></Recharge> -->
  </div>
</template>

<script>
import moment from 'moment'
// import Recharge from './commonents/Recharge'
import to from 'await-to'
import { getAccountsUpdate, getAccountsMoney } from '@/api/college'
export default {
  components: {
    // Recharge,
  },
  data() {
    return {
      checked: false,
      tableData: [
        {
          id: '平台使用费',
          name: '12900.00',
          amount1: '1',
          amount2: '0',
          year: '1',
        },
        {
          id: '使用人数',
          name: '48',
          amount1: '',
          amount2: '',
          year: '1',
        },
      ],
      accountsDetail: {},
      // num: this.$route.query.buyNum,
      // orderNo: this.$route.query.orderNo,
      // year: this.$route.query.year,
      num: this.$route.params.buyNum,
      orderNo: this.$route.params.orderNo,
      year: this.$route.params.year,
      startEffectiveTime: '',
      endEffectiveTime: '',
    }
  },
  created() {
    this.getAccountsMoneyData()
    this.startEffectiveTime = moment().format('YYYY-MM-DD')
    this.endEffectiveTime = moment()
      .add(this.month, 'month')
      .format('YYYY-MM-DD')
  },
  methods: {
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.accountsDetail = res.data
    },
    async getAccountsUpdateData() {
      const [, err] = await to(getAccountsUpdate({ orderNo: this.orderNo, num: this.num }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '支付成功' })
      this.$router.push('/college/updateSuccess')
    },
    recharge() {
      // this.$refs.Recharge.show()
      this.$router.push({
        name: 'AccountsManage',
        params: {
          showRecharge: 1,
        },
      })
    },
    submitPay() {
      this.getAccountsUpdateData()
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      // console.log(columns, data)
      var price = 0
      data.map(v => {
        if (v.id == '使用人数') {
          v.amount1 = this.num
          v.amount2 = v.amount1 * v.name * this.year
          v.year = this.year
          price += Number(v.amount2)
        }
        if (v.id == '平台使用费') {
          v.year = this.year
        }
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = price + '元'
          return
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.wrap {
  .input-width {
    width: 120px;
    height: 34px;
  }
  .title {
    line-height: 42px;
    font-size: 16px;
    color: #606266;
    background-color: #f6f6f6;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .content {
    font-size: 14px;
    color: #333;
    .orderDetail {
      ::v-deep .el-form-item__content {
        line-height: 0 !important;
      }
    }
    .people {
      margin-top: 22px;
    }
    .payType {
      background-color: #fff5f0;
      display: flex;
      align-items: center;
      padding-left: 20px;
      img {
        width: 20px;
        height: 17px;
        margin: auto;
      }
      .balancePay {
        display: flex;
        align-items: center;
        .balance {
          margin-left: 6px;
          color: #333;
        }
      }
      .nowMoney {
        color: #909399;
        margin: 0 39px 0 12px;
        .moneyNum {
          color: #ff7b33;
        }
      }
      .recharge {
        line-height: 24px;
        text-align: center;
        color: #ff7b33;
        border: 1px solid #ff7b33;
        cursor: pointer;
        padding: 0 12px;
      }
    }
  }
}
.agreement {
  margin-top: 38px;
  .agreement_checked {
    display: flex;
    line-height: 20px;
    margin-left: 150px;
    .agreement_text {
      color: #909399;
      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .btn {
    margin-left: 150px;
    margin-top: 17px;
  }
}
.accounts {
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .radio_wrap {
    display: flex;
    height: 40px;
    line-height: 40px;
    .radio_img {
      height: 20px;
      width: 20px;
      .weixin {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
